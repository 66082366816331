<template>
    <div class="nav">
        <div class="empty"></div>
        <el-row class="content">
            <!-- <el-col class="dropdown df ac jc hidden-sm-and-up" :span="2" :xs="4">
                <el-dropdown trigger="click" @command="returnTop">
                    <span class="el-dropdown-link">
                        <i class="icon-dropdown el-icon-s-unfold"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="#introduce">{{$t("introduce")}}</el-dropdown-item>
                            <el-dropdown-item command="#clubProfile">{{$t("clubProfile")}}</el-dropdown-item>
                            <el-dropdown-item command="#attentionClub">{{$t("attentionClub")}}</el-dropdown-item>
                            <el-dropdown-item command="#applyForMembership1">{{$t("applyForMembership1")}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col> -->
            <el-col class="logo df ac jc" :span="2">
                <img src="@/assets/images/big-logo.png">
            </el-col>
            <el-col class="menu df ac sb" :span="16" :offset="1">
                <span @click="returnTop('#introduce')">{{$t("introduce")}}</span>
                <span @click="returnTop('#clubProfile')">{{$t("clubProfile")}}</span>
                <span @click="returnTop('#attentionClub')">{{$t("attentionClub")}}</span>
                <span @click="returnTop('#applyForMembership1')">{{$t("applyForMembership1")}}</span>
            </el-col>
            <el-col class="login df ac jc" :span="4" :offset="1">
                <div @click="login">
                    <i class="el-icon-user-solid" />
                    <span class="login-btn">{{$t("login")}}</span>
                </div>
                <el-select size="mini" v-model="value" placeholder=" " @change="valueChange">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { toRefs, reactive, defineComponent, onMounted } from 'vue'
export default defineComponent({
    name: 'Nav',
    emits: {
        login: (v) => {
            return v
        }
    },
    setup(props, context) {
        const state = reactive({
            options: [
                {
                    value: 'zh',
                    label: 'zh'
                },
                {
                    value: 'en',
                    label: 'en'
                }
            ],
            value: 'zh'
        })
        const store = useStore()
        function valueChange(v) {
            store.commit('setLocale', v)
        }
        onMounted(() => {
            state.value = store.getters.locale
        })
        function returnTop(id, e) {
            if (e) {
                e.preventDefault()
            }
            document.querySelector(id).scrollIntoView(true)
        }
        function login() {
            context.emit('login')
        }
        return { ...toRefs(state), valueChange, returnTop, login }
    }
})
</script>
<style lang="scss">
.nav {
    height: 60px;
    width: 100%;
    .empty {
        height: 100%;
        width: 100%;
    }
    .content {
        background-color: #253238;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        color: #fff;
        z-index: 10;
        .df {
            display: flex;
        }
        .ac {
            align-items: center;
        }
        .jc {
            justify-content: center;
        }
        .sb {
            justify-content: space-between;
        }
        .sa {
            justify-content: space-around;
        }
        .dropdown {
            .icon-dropdown {
                font-size: 26px;
                color: #fff;
            }
        }
        .logo {
            img {
                display: block;
                width: 70px;
                height: 40px;
            }
        }
        .menu {
            span {
                cursor: pointer;
            }
        }
        .login {
            cursor: pointer;
            &-btn {
                margin-left: 6px;
                white-space: nowrap;
            }
            .el-icon-user-solid {
                font-size: 18px;
            }
            .el-select {
                width: 46px;
                margin-left: 10px;
                .el-input__inner {
                    padding: 0 0 0 6px;
                }
                .el-input__suffix {
                    right: 0;
                }
            }
            .el-dropdown {
                margin: 0 10px;
                padding-right: 20px;
            }
        }
    }
}
</style>
