<template>
  <div class="dialogDiv">
    <el-dialog
      v-model="visible"
      custom-class="dialog"
      :show-close="false"
      width="600px"
      style="background: url('../../../assets/images/login-bg.png') no-repeat;background-size: cover;"
    >
      <template #title>
        <el-row class="header">
          <el-col class="header-item" :span="24">
            <div class="back" @click.stop="back">
              <i class="el-icon-arrow-left" /> {{ $t('back') }}
            </div>
            <div class="login">
              <el-tabs v-model="activeName">
                <el-tab-pane :label="$t('login')" name="Login"></el-tab-pane>
                <el-tab-pane
                  :label="$t('register')"
                  name="Register"
                ></el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </template>
      <el-row class="dialog-row">
        <el-col class="content" :span="24">
          <el-row class="form" v-show="activeName === 'Login' && !isForget">
            <el-form
              ref="form11"
              :model="form1"
              :rules="allRules"
              :label-width="labelWidth"
              label-position="left"
            >
              <!-- <el-form-item prop="email" :label="$t('pEmail')">
                                <el-input :placeholder="$t('pEmail')" v-model="form1.email" autocomplete="on" maxlength="50">
                                    <template v-slot:prefix>
                                        <i class="icon el-icon-user-solid" />
                                    </template>
                                </el-input>
                            </el-form-item> -->
              <el-form-item prop="memberId" :label="$t('pMemberId')">
                <el-input
                  :placeholder="$t('pMemberId')"
                  v-model="form1.memberId"
                  autocomplete="on"
                  maxlength="10"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" :label="$t('pPassword')">
                <el-input
                  :placeholder="$t('pPassword')"
                  v-model="form1.password"
                  type="password"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_clock.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-checkbox style="margin-bottom:10px;" v-model="saveInfo">{{
                $t('saveInfomation')
              }}</el-checkbox>
            </el-form>
            <el-row class="btn">
              <el-button
                :class="{ 'btn-disabled': form1Disabled }"
                @click="login"
                >{{ $t('login') }}</el-button
              >
              <span class="reLogin" @click="forgetPassword">{{
                $t('forgetPassword')
              }}</span>
            </el-row>
          </el-row>
          <el-row class="form" v-show="activeName === 'Register'">
            <el-form
              ref="form22"
              :model="form2"
              :rules="allRules"
              :label-width="labelWidth"
              label-position="left"
            >
              <el-form-item prop="email" :label="$t('pEmail')">
                <el-input
                  :placeholder="$t('pEmail')"
                  v-model="form2.email"
                  maxlength="50"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_email.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="mobileNum" :label="$t('pMobileNum')">
                <el-input
                  :placeholder="$t('pMobileNum')"
                  type="number"
                  v-model="form2.mobileNum"
                  maxlength="11"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-phone" />
                  </template>
                </el-input>
              </el-form-item>
              <!-- <el-form-item prop="nickname" :label="$t('pNickname')">
                <el-input
                  :placeholder="$t('pNickname')"
                  v-model="form2.nickname"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-input>
              </el-form-item> -->
              <el-form-item prop="surname" :label="$t('pSurname')">
                <el-input
                  :placeholder="$t('pSurname')"
                  v-model="form2.surname"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="givenName" :label="$t('pGivenName')">
                <el-input
                  :placeholder="$t('pGivenName')"
                  v-model="form2.givenName"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="gender" :label="$t('pGender')">
                <el-select v-model="form2.gender" :placeholder="$t('pGender')">
                  <el-option
                    v-for="item in genderList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item prop="birthDateStr" :label="$t('pBirth')">
                <el-date-picker
                  v-model="form2.birthDateStr"
                  type="date"
                  :placeholder="$t('pBirth')"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="handicapNum" :label="$t('pHandicapNo')">
                <el-input
                  :placeholder="$t('errorHandicapNumber')"
                  v-model="form2.handicapNum"
                  type="Number"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_handicap.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="ecPerson" :label="$t('pEcPerson')">
                <el-input
                  :placeholder="$t('pEcPerson')"
                  v-model="form2.ecPerson"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="ecNum" :label="$t('pEcNum')">
                <el-input
                  :placeholder="$t('pEcNum')"
                  v-model="form2.ecNum"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <i class="icon el-icon-phone" />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="shirtSize" :label="$t('pShirtSize')">
                <el-select
                  v-model="form2.shirtSize"
                  :placeholder="$t('pShirtSize')"
                >
                  <el-option
                    v-for="item in shirtSizeList"
                    :key="item.codeKey"
                    :label="item.codeKey"
                    :value="item.codeValue"
                  >
                  </el-option>
                  <template v-slot:prefix>
                    <i class="icon el-icon-user-solid" />
                  </template>
                </el-select>
              </el-form-item>

              <el-form-item prop="nationality" :label="$t('pNationality')">
                <el-input
                  :placeholder="$t('pNationality')"
                  v-model="form2.nationality"
                  maxlength="50"
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="businessType" :label="$t('pBusinessType')">
                <el-input
                  :placeholder="$t('pBusinessType')"
                  v-model="form2.businessType"
                  maxlength="50"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('pPhoto')" required>
                <el-upload
                  class="upload-demo"
                  action="action"
                  :on-change="handleChange"
                  :auto-upload="false"
                  :show-file-list="false"
                >
                  <el-image
                    v-if="form2.profilePicture"
                    :src="VUE_APP_IMAGE_URL + form2.profilePicture"
                    style="width: 40px; height: 40px"
                  ></el-image>
                  <el-button v-else size="small" type="primary">
                    {{ $t('btnUpload') }}
                  </el-button>
                </el-upload>
              </el-form-item>
              <el-form-item prop="password" :label="$t('pPassword')">
                <el-input
                  :placeholder="$t('pPassword')"
                  v-model="form2.password"
                  type="password"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_clock.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item
                prop="confirmPassword"
                :label="$t('pConfirmPassword')"
              >
                <el-input
                  :placeholder="$t('pConfirmPassword')"
                  v-model="form2.confirmPassword"
                  type="password"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_clock.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item> </el-form-item>
              <el-form-item style="text-align: center;"> </el-form-item>
            </el-form>
            <el-row class="btn">
              <el-button
                :class="{ 'btn-disabled': form2Disabled }"
                @click="register"
                >{{ $t('signUp') }}</el-button
              >
              <p class="reLogin" @click="reLogin">
                <span>{{ $t('alreadyAccount') }}</span>
                <span>{{ $t('login') }}</span>
              </p>
            </el-row>
          </el-row>
          <el-row class="form" v-show="activeName === 'Login' && isForget">
            <el-form
              ref="form33"
              :model="form3"
              :rules="allRules"
              :label-width="labelWidth"
              label-position="left"
            >
              <el-form-item prop="email" :label="$t('pEmail')">
                <el-input
                  :placeholder="$t('pEmail')"
                  v-model="form3.email"
                  maxlength="50"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_email.png"
                      class="icon"
                    />
                  </template>
                  <template v-slot:append>
                    <span
                      v-if="form3.time <= 0"
                      class="sendOtp"
                      @click.stop="sendOtp"
                      >{{ $t('sendOtp') }}</span
                    >
                    <span v-else class="sendOtp2"
                      >{{ $t('reSend') }}{{ form3.time }}s</span
                    >
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="code" :label="$t('pOtpCode')">
                <el-input
                  :placeholder="$t('pOtpCode')"
                  v-model="form3.code"
                  maxlength="10"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_yaoshi.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" :label="$t('pPassword')">
                <el-input
                  :placeholder="$t('pPassword')"
                  v-model="form3.password"
                  type="password"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_clock.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="newPassword" :label="$t('pConfirmPassword')">
                <el-input
                  :placeholder="$t('pConfirmPassword')"
                  v-model="form3.newPassword"
                  type="password"
                  maxlength="20"
                >
                  <template v-slot:prefix>
                    <img
                      src="@/assets/images/icons/ic_clock.png"
                      class="icon"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item> </el-form-item>
            </el-form>
            <el-row class="btn">
              <el-button
                :class="{ 'btn-disabled': form3Disabled }"
                @click="resetPassword"
                >{{ $t('resetPassword') }}</el-button
              >
              <p class="reLogin" @click="reLogin">
                <span>{{ $t('alreadyAccount') }}</span>
                <span>{{ $t('login') }}</span>
              </p>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  computed,
  getCurrentInstance,
  onMounted
} from 'vue'
import i18n from '@/lang'
import {
  loginToAdmin,
  memRegister,
  sendEmail,
  renewPasswordByVerifCode,
  imageUpload
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    show: {
      type: Number
    },
    registerNum: {
      type: Number
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      visible: false,
      activeName: 'Login',
      isForget: false,
      saveInfo: true,
      form1: {
        memberId: '',
        password: ''
      },
      form2: {
        email: '',
        mobileNum: '',
        nickname: '',
        surname: '',
        givenName: '',
        handicapNum: '',
        gender: '',
        birthDateStr: '',
        ecPerson: '',
        ecNum: '',
        shirtSize: '',
        password: '',
        confirmPassword: '',
        nationality: '',
        businessType: '',
        profilePicture: ''
      },
      form3: {
        email: '',
        code: '',
        password: '',
        newPassword: '',
        time: 0
      },
      gender: {
        en: [
          { label: 'Male', value: 'M' },
          { label: 'Female', value: 'F' }
        ],
        zh: [
          { label: '男', value: 'M' },
          { label: '女', value: 'F' }
        ]
      },
      shirtSizeList: [
        {
          codeKey: 'XXS',
          codeValue: 'XXS',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'XS',
          codeValue: 'XS',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'S',
          codeValue: 'S',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'M',
          codeValue: 'M',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'L',
          codeValue: 'L',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'XL',
          codeValue: 'XL',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'XXL',
          codeValue: 'XXL',
          codeDesc: 'The menber shirt size list'
        },
        {
          codeKey: 'XXXL',
          codeValue: 'XXXL',
          codeDesc: 'The menber shirt size list'
        }
      ],
      labelWidth: '230px'
    })
    watch(
      () => [props.registerNum],
      () => {
        state.visible = true
        state.activeName = 'Register'
      }
    )
    const dom = getCurrentInstance()
    const ctx = (dom as any).ctx
    function getInfoFn() {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        state.form1 = info
      }
    }
    function saveInfoFn() {
      if (state.saveInfo) {
        const info = JSON.stringify(state.form1)
        localStorage.setItem('userInfo', info)
      } else {
        localStorage.setItem('userInfo', JSON.stringify(null))
      }
    }
    function login() {
      const form11 = (dom as any).refs.form11
      form11.validate(async (res: boolean) => {
        if (res) {
          const data = await loginToAdmin(state.form1)
          if ((data as any).code === '1') {
            ElMessage.success(data.data.message)
            localStorage.setItem('accessToken', data.data.accessToken)
            saveInfoFn()
            //跳转
            const isStaging = process.env.VUE_APP_ENV === 'staging'
            const isProd = process.env.VUE_APP_ENV === 'production'
            if (isStaging) {
              location.replace(location.origin + '/golf')
            }
            if (isProd) {
              location.replace(location.origin + '/customer')
            }
          } else {
            ElMessage.error(data.data.message)
          }
        }
      })
    }
    function reLogin() {
      state.activeName = 'Login'
      state.isForget = false
    }
    function dateFormat(fmt: string, date: Date) {
      let ret
      const opt: any = {
        'Y+': date.getFullYear().toString(),
        'm+': (date.getMonth() + 1).toString(),
        'd+': date.getDate().toString(),
        'H+': date.getHours().toString(),
        'M+': date.getMinutes().toString(),
        'S+': date.getSeconds().toString()
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    }
    async function register() {
      const form22 = (dom as any).refs.form22
      form22.validate(async (res: boolean) => {
        if (res) {
          const param = { ...state.form2 }
          param.birthDateStr = dateFormat(
            'dd/mm/YYYY',
            new Date(state.form2.birthDateStr)
          )
          const data = await memRegister(param)
          if ((data as any).code === '1') {
            ElMessage.success(data.data.message)
            // ctx.$alert(data.data.message, '', {
            //     confirmButtonText: ctx.$t('confirm'),
            //     showClose: false,
            //     confirmButtonClass: 'confirmBtn',
            //     callback: () => {
            //         reLogin()
            //     }
            // })
          } else {
            ElMessage.error(data.data.message)
          }
        }
      })
    }
    function forgetPassword() {
      state.isForget = true
    }
    let interval1: any = null
    async function sendOtp() {
      if (!state.form3.email) {
        ElMessage.error(ctx.$t('errorEmail'))
      } else {
        const data = await sendEmail({ email: state.form3.email })
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)
          state.form3.time = 60
          interval1 = setInterval(() => {
            state.form3.time--
            if (state.form3.time <= 0) {
              clearInterval(interval1)
            }
          }, 1000)
        } else {
          ElMessage.error(data.data.message)
        }
      }
    }
    function resetPassword() {
      const form33 = (dom as any).refs.form33
      form33.validate(async (res: boolean) => {
        if (res) {
          const param = {
            code: state.form3.code,
            email: state.form3.email,
            newPassword: state.form3.newPassword
          }
          const data = await renewPasswordByVerifCode(param)
          console.log(data)
          if ((data as any).code === '1') {
            ElMessage.success(data.data.message)
            // state.form1.email = param.email
            // state.form1.password = param.newPassword
            reLogin()
          } else {
            ElMessage.error(data.data.message)
          }
        }
      })
    }
    function back() {
      if (state.activeName === 'Login' && state.isForget) {
        state.isForget = false
        return
      }
      state.visible = false
    }
    function getText(params: string) {
      return i18n.global.t(params)
    }
    function checkConfirmPassword1(rule: any, value: any, callback: any) {
      if (!value || state.form2.password !== value) {
        callback(new Error(ctx.$t('errorPassword2')))
      } else {
        callback()
      }
    }
    function checkConfirmPassword2(rule: any, value: any, callback: any) {
      if (!value || state.form3.password !== value) {
        callback(new Error(ctx.$t('errorPassword2')))
      } else {
        callback()
      }
    }
    function checkMobileNum(rule: any, value: any, callback: any) {
      const reg = /^[0-9]{8,11}$/g
      if (!reg.test(value)) {
        callback(new Error(ctx.$t('errorMobileNum')))
      } else {
        callback()
      }
    }
    function checkEcNum(rule: any, value: any, callback: any) {
      const reg = /^[0-9]{8,11}$/g
      if (!reg.test(value)) {
        callback(new Error(ctx.$t('errorEcNum')))
      } else {
        callback()
      }
    }
    function checkOtpCode(rule: any, value: any, callback: any) {
      const codeReg = /^[1-9]\d*$/g
      if (!codeReg.test(value)) {
        callback(new Error(ctx.$t('errorOtp')))
      } else {
        callback()
      }
    }
    const allRules = computed(() => {
      return {
        memberId: [
          {
            required: true,
            message: getText('errorMemberId'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: getText('errorEmail'),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: getText('errorEmail'),
            trigger: ['blur', 'change']
          }
        ],
        mobileNum: [
          {
            required: true,
            validator: checkMobileNum,
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            required: true,
            validator: checkOtpCode,
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: getText('errorPassword1'),
            trigger: 'blur'
          },
          {
            min: 6,
            message: getText('errorPassword1'),
            trigger: ['blur', 'change']
          }
        ],
        // nickname: [
        //   {
        //     required: true,
        //     message: getText('errorNickname'),
        //     trigger: 'blur'
        //   }
        // ],
        surname: [
          {
            required: true,
            message: getText('errorSurname'),
            trigger: 'blur'
          }
        ],
        givenName: [
          {
            required: true,
            message: getText('errorGivenName'),
            trigger: 'blur'
          }
        ],
        handicapNum: [
          {
            required: true,
            message: getText('errorHandicapNum'),
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: getText('errorGender'),
            trigger: ['blur', 'change']
          }
        ],
        birthDateStr: [
          {
            required: true,
            message: getText('errorBirth'),
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: checkConfirmPassword1,
            trigger: ['blur', 'change']
          }
        ],
        newPassword: [
          {
            required: true,
            validator: checkConfirmPassword2,
            trigger: ['blur', 'change']
          }
        ],
        shirtSize: [
          {
            required: true,
            message: getText('errorShirtSize'),
            trigger: 'blur'
          }
        ],
        nationality: [
          {
            required: true,
            message: getText('errorNationality'),
            trigger: 'blur'
          }
        ],
        businessType: [
          {
            required: true,
            message: getText('errorBusinessType'),
            trigger: 'blur'
          }
        ],
        ecPerson: [
          {
            required: true,
            message: getText('errorEcPerson'),
            trigger: 'blur'
          }
        ],
        ecNum: [
          {
            required: true,
            validator: checkEcNum,
            trigger: 'blur'
          }
        ]
      }
    })

    const genderList = computed(() => {
      return state.gender[ctx.$i18n.locale]
    })
    onMounted(() => {
      const memberIdStr = store.getters.memberIdStr
      if (memberIdStr) {
        state.form1.memberId = memberIdStr
        state.visible = true
        store.commit('setMemberIdStr', '')
      }
      getInfoFn()
    })
    const form1Disabled = computed(() => {
      let disabled = false
      for (const i in state.form1) {
        if (!state.form1[i] && !disabled) {
          disabled = true
        }
      }
      return disabled
    })
    const form2Disabled = computed(() => {
      let disabled = false
      for (const i in state.form2) {
        if (!state.form2[i] && !disabled) {
          disabled = true
        }
      }
      return disabled
    })
    const form3Disabled = computed(() => {
      const { email, code, password, newPassword } = state.form3
      const form3 = { email, code, password, newPassword }
      let disabled = false
      for (const i in form3) {
        if (!form3[i] && !disabled) {
          disabled = true
        }
      }
      return disabled
    })
    watch(
      () => [props.show],
      () => {
        state.visible = true
        state.activeName = 'Login'
      }
    )

    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL
    function handleChange(file: any) {
      const param = new FormData()
      param.append('file', file.raw)
      imageUpload(param).then(res => {
        console.log(res)
        const { data } = res
        if (data && data.success) {
          const { filePath } = data
          state.form2.profilePicture = filePath
        }
      })
    }
    return {
      ...toRefs(state),
      watch,
      reLogin,
      forgetPassword,
      back,
      getText,
      allRules,
      login,
      register,
      sendOtp,
      resetPassword,
      genderList,
      form1Disabled,
      form2Disabled,
      form3Disabled,
      saveInfoFn,
      getInfoFn,
      VUE_APP_IMAGE_URL,
      handleChange
    }
  }
})
</script>
<style lang="scss">
.confirmBtn {
  background-color: #95c051;
  border-color: #95c051;
  &:hover {
    background-color: #95c051;
    border-color: #95c051;
  }
}
</style>
<style lang="scss" scoped>
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog__header {
    display: none;
  }
  .header {
    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      .back {
        margin-top: 15px;
        color: #52a2eb;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
      }
      .login {
        :deep(.el-tabs__item) {
          font-size: 16px;
        }
      }
    }
  }
  .dialog-row {
    justify-content: center;
    .content {
      .form {
        .el-form {
          width: 80%;
          margin: 22px auto 0;
          :deep(.el-input__prefix) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .el-select,
          :deep(.el-date-editor.el-input) {
            width: 100%;
          }
          :deep(.el-input-group__append) {
            padding: 0 5px;
          }
          .icon {
            width: 16px;
            height: 16px;
            font-size: 16px;
            color: #000;
          }

          .sendOtp {
            cursor: pointer;
            color: #007bff;
            font-size: 12px;
          }
          .sendOtp2 {
            cursor: pointer;
            color: #ccc;
            font-size: 12px;
          }
        }
        .btn {
          width: 80%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          text-align: center;
          .el-button {
            width: 100%;
            background-color: #95c051;
            color: #fff;
          }
          .reLogin {
            margin-top: 20px;
            cursor: pointer;
            color: #007bff;
          }
          .btn-disabled {
            background-color: #8fa7b2;
          }
        }
      }
    }
  }
}
/deep/ .el-dialog {
  background: url('../../../assets/images/login-bg.png') no-repeat;
  background-size: cover;
}
</style>
